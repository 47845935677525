class Modal {
    constructor(
        element,
        {
            hidden = true,
            visibleClass = "visible",
            hiddenClass = "hidden",
            onOpen = () => undefined,
            onClose = () => undefined,
            noBodyOverflowWhenVisible = true,
        } = {}
    ) {
        if (visibleClass === undefined && hiddenClass === undefined) {
            throw new Error("Modal visibleClass and hiddenClass can't be undefined at the same time.");
        }
        // Fetching the element
        this.element = typeof element === "string" ? document.querySelector(element) : element;
        if (this.element === null) {
            throw new Error(`Modal element couldn't be found with given query "${element}"`);
        }
        this.classNames = {
            visible: visibleClass,
            hidden: hiddenClass,
        };
        // Meta
        this.noBodyOverflowWhenVisible = noBodyOverflowWhenVisible;
        // Event handlers
        this.onOpen = onOpen;
        this.onClose = onClose;
        // Hidden / visible state
        this.setHidden(hidden);
    }
    // Getters & setters
    get isHidden() {
        return this.classNames.hidden !== undefined
            ? this.element.classList.contains(this.classNames.hidden)
            : !this.element.classList.contains(this.classNames.visible);
    }
    // Utils
    setHidden = (hidden) => {
        if (hidden === this.isHidden) return;
        const oldClass = !hidden ? this.classNames.hidden : this.classNames.visible;
        const newClass = hidden ? this.classNames.hidden : this.classNames.visible;
        if (oldClass !== undefined) this.element.classList.remove(oldClass);
        if (newClass !== undefined) this.element.classList.add(newClass);
        this.element.dataset.hidden = hidden ? "true" : "false";
        if (hidden === true) this.onClose();
        else this.onOpen();
        if (this.noBodyOverflowWhenVisible) this._toggleBodyOverflow(hidden);
        return this;
    };
    open() {
        return this.setHidden(false);
    }
    close() {
        return this.setHidden(true);
    }
    // static getById = (id: number) => MODAL_INSTANCES.get(id)
    // static deleteInstance = (id: number) => MODAL_INSTANCES.delete(id)
    // Content
    setContent = (content) => {
        if (typeof content === "string") {
            this.element.innerHTML = content;
        } else {
            this.element.replaceChildren(content);
        }
        return this;
    };
    assignCloseButton(button) {
        button.addEventListener("click", () => {
            this.close();
        });
        return this;
    }
    _toggleBodyOverflow(overflow) {
        document.querySelector("body").style.overflow = overflow ? "" : "hidden !important";
        document.querySelector("body").style.overflow = overflow ? "" : "hidden";
        return this;
    }
}

const booleanToBinary = (bool) => (bool ? 1 : 0);
const getVimeoID = (url) => {
    const regExp = /https:\/\/(www\.)?vimeo.com\/(\d+)($|\/)/;
    const match = url.match(regExp);
    if (match) return match[2];
    throw Error(`URL ERROR: could not find a valid vimeo id.\n"${url}" is not a valid url. `);
};
const vimeoToEmbedUrl = (url, { autoPlay = true, controls = true } = {}) =>
    `https://player.vimeo.com/video/${getVimeoID(url)}?autoplay=${booleanToBinary(
        autoPlay
    )}&controls=${booleanToBinary(controls)}`;

const getYoutubeID = (url) => {
    const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
    const match = url.match(regExp);
    if (match && match[2].length === 11) return match[2];
    throw Error(`URL ERROR: could not find a valid youtube id.\n"${url}" is not a valid url. `);
};
const youtubeToEmbedUrl = (url, { autoPlay = true, controls = true } = {}) =>
    `https://www.youtube.com/embed/${getYoutubeID(url)}?autoplay=${booleanToBinary(
        autoPlay
    )}&controls=${booleanToBinary(controls)}`;

document.querySelectorAll("[data-component='VideoList']").forEach((videoList) => {
    // State
    let currentVidID;
    const videoUrls = [];
    // Elements
    const videoModal = new Modal(document.querySelector("[data-modal='youtubeModal']"));
    const iframe = videoModal.element.querySelector("iframe");
    const buttons = {
        previous: videoModal.element.querySelector("[data-button='previous']"),
        next: videoModal.element.querySelector("[data-button='next']"),
    };
    const hasCycleButtons = buttons.next !== null && buttons.previous !== null;
    // Helpers
    const clickToClose = (e) => {
        const modalContent = videoModal.element.querySelector("[data-modal-content]");
        if (!modalContent.contains(e.target)) videoModal.close();
    };
    const escapeToClose = (e) => {
        if (e.key === "Escape") videoModal.close();
    };
    const setVideo = (ID, openModal = true) => {
        currentVidID = ID;
        iframe.src = videoUrls[ID];
        if (hasCycleButtons) {
            setButtonDisabled(buttons.previous, ID <= 0);
            setButtonDisabled(buttons.next, ID >= videoUrls.length - 1);
        }
        if (openModal) videoModal.open();
    };
    const setButtonDisabled = (button, disabled) => {
        button.setAttribute(
            "style",
            disabled ? "opacity: 0.2; cursor: not-allowed; pointer-events: none;" : ""
        );
    };
    // Script
    videoModal.element
        .querySelector("[data-role='modalClose']")
        .addEventListener("click", () => videoModal.close());
    videoList.querySelectorAll("[data-video]").forEach((videoElement, index) => {
        const videoUrl = videoElement.dataset.video;
        try {
            const embedUrl = videoUrl.includes("vimeo")
                ? vimeoToEmbedUrl(videoUrl)
                : youtubeToEmbedUrl(videoUrl);
            videoUrls.push(embedUrl);
            videoElement.addEventListener("click", (e) => {
                e.preventDefault();
                setVideo(index);
            });
        } catch (err) {
            console.error(err);
        }
    });
    videoModal.onOpen = () => {
        // SetTimeout to bypass event loop funkyness
        // Will set the eventListener when the next job starts
        setTimeout(() => document.addEventListener("click", clickToClose), 0);
        document.addEventListener("keydown", escapeToClose);
    };
    videoModal.onClose = () => {
        iframe.src = "";
        document.removeEventListener("click", clickToClose);
        document.removeEventListener("keydown", escapeToClose);
    };
    // Optional
    buttons.previous?.addEventListener("click", () => {
        setVideo(currentVidID - 1, false);
    });
    buttons.next?.addEventListener("click", () => {
        setVideo(currentVidID + 1, false);
    });
});
